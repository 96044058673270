import { LetterGuess, LetterStatus, wordLen } from '../../lib/words'
import { Cell } from './Cell'
import { PossiblesCell } from './PossiblesCell'

type Props = {
  currentGuessState: LetterGuess
  onCellClick: (index: number, value?: string, status?: LetterStatus) => void
}

export const ScratchRow = ({ currentGuessState, onCellClick }: Props) => {
  const localClick = (index: number, value?: string, status?: LetterStatus) => {
    onCellClick(index, value, status)
  }

  const cells = []
  const possiblesGrid = currentGuessState.getPossiblesGrid()

  for (let i = 0; i < wordLen; i++) {
    const possibles = possiblesGrid[i]
    if (possibles.length === 1 && possibles[0].status === 'correct') {
      // only one possible, must be green
      cells.push(
        <Cell
          onClick={localClick}
          key={possibles[0].index}
          highlight={false}
          location={possibles[0].index}
          value={possibles[0].letter}
          status={'correct'}
          warn={false}
        />
      )
    } else if (possibles.length >= 1) {
      // if we have letter possibilities to show
      const showValue = currentGuessState.showPossiblesAt(i)
      cells.push(
        <PossiblesCell
          usedLetters={currentGuessState.getUsedLetters()}
          onClick={localClick}
          showValues={showValue}
          key={`pc${i}+${possibles.length}`}
          location={i}
          highlight={false}
          values={possibles}
          warn={false}
        />
      )
    } else {
      // just blank?
      cells.push(
        <Cell
          onClick={localClick}
          highlight={false}
          key={i}
          location={currentGuessState.letters.length + i}
        />
      )
    }
  }
  return (
    <div className="flex justify-center mb-1">{cells.map((cell) => cell)}</div>
  )
}
