import { LetterStatus } from '../../lib/words'
import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  const onClick = (index: number, value?: string, status?: LetterStatus) => {
    console.log('Info Modal Click', value, index, status)
  }

  return (
    <BaseModal title="How to use" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Sometimes you just need a little help. These hints won't guarantee a
        win, but can help a lot. Open this window next to the popular word
        guessing game. After you make each guess in the word game, enter the
        same state here and click enter. Click a letter multiple times to change
        state to match your guess.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell location={-1} onClick={onClick} value="W" status="correct" />
        <Cell location={-1} onClick={onClick} value="E" status="killed" />
        <Cell location={-1} onClick={onClick} value="A" status="present" />
        <Cell location={-1} onClick={onClick} value="R" status="killed" />
        <Cell location={-1} onClick={onClick} value="Y" status="correct" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        You made a great guess! Lexible knows that with this guess there's only
        one possible win! Congrats!
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell location={-1} onClick={onClick} value="P" status="killed" />
        <Cell location={-1} onClick={onClick} value="I" status="killed" />
        <Cell location={-1} onClick={onClick} value="L" status="present" />
        <Cell location={-1} onClick={onClick} value="O" status="killed" />
        <Cell location={-1} onClick={onClick} value="T" status="killed" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Not bad, but, over 100 possible wins. Will you try 'LEASE' or 'SCALE'
        next?!
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell location={-1} onClick={onClick} value="S" status="present" />
        <Cell location={-1} onClick={onClick} value="T" status="killed" />
        <Cell location={-1} onClick={onClick} value="R" status="killed" />
        <Cell location={-1} onClick={onClick} value="I" status="correct" />
        <Cell location={-1} onClick={onClick} value="P" status="killed" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        This one tells you a lot more than you might think!
      </p>
    </BaseModal>
  )
}
