import { KeyValue } from '../../lib/keyboard'
import { Key } from './Key'
import { wordLen } from '../../lib/words'
import { useEffect } from 'react'
import { ENTER_TEXT, DELETE_TEXT } from '../../constants/strings'
import { LetterGuess } from '../../lib/words'

type Props = {
  currentGuessState: LetterGuess
  onChar: (value: string) => void
  onDelete: () => void
  onEnter: (special: boolean) => void
}

export const Keyboard = ({
  currentGuessState,
  onChar,
  onDelete,
  onEnter,
}: Props) => {
  useEffect(() => {
    //console.log('current guess state changing on keyboard')
  }, [currentGuessState])
  // const charStatuses = getStatuses([])

  const onClick = (value: KeyValue) => {
    if (value === 'ENTER') {
      onEnter(false)
    } else if (value === 'DELETE') {
      onDelete()
    } else {
      onChar(value)
    }
  }

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        onEnter(e.altKey || e.ctrlKey)
      } else if (e.code === 'Backspace') {
        onDelete()
      } else {
        const key = e.key.toUpperCase()
        if (
          key.length === 1 &&
          ((key >= 'A' && key <= 'Z') || (key >= '1' && key <= `${wordLen}`))
        ) {
          onChar(key)
        }
      }
    }
    window.addEventListener('keyup', listener)
    return () => {
      window.removeEventListener('keyup', listener)
    }
  }, [onEnter, onDelete, onChar])

  return (
    <div>
      <div className="flex justify-center mb-1">
        <Key
          value="Q"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('Q')}
        />
        <Key
          value="W"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('W')}
        />
        <Key
          value="E"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('E')}
        />
        <Key
          value="R"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('R')}
        />
        <Key
          value="T"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('T')}
        />
        <Key
          value="Y"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('Y')}
        />
        <Key
          value="U"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('U')}
        />
        <Key
          value="I"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('I')}
        />
        <Key
          value="O"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('O')}
        />
        <Key
          value="P"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('P')}
        />
      </div>
      <div className="flex justify-center mb-1">
        <Key
          value="A"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('A')}
        />
        <Key
          value="S"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('S')}
        />
        <Key
          value="D"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('D')}
        />
        <Key
          value="F"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('F')}
        />
        <Key
          value="G"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('G')}
        />
        <Key
          value="H"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('H')}
        />
        <Key
          value="J"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('J')}
        />
        <Key
          value="K"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('K')}
        />
        <Key
          value="L"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('L')}
        />
      </div>
      <div className="flex justify-center">
        <Key width={65.4} value="ENTER" onClick={onClick}>
          {ENTER_TEXT}
        </Key>
        <Key
          value="Z"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('Z')}
        />
        <Key
          value="X"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('X')}
        />
        <Key
          value="C"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('C')}
        />
        <Key
          value="V"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('V')}
        />
        <Key
          value="B"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('B')}
        />
        <Key
          value="N"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('N')}
        />
        <Key
          value="M"
          onClick={onClick}
          status={currentGuessState.getLetterStatus('M')}
        />
        <Key width={65.4} value="DELETE" onClick={onClick}>
          {DELETE_TEXT}
        </Key>
      </div>
    </div>
  )
}
