import classnames from 'classnames'

type Props = {
  count?: boolean
  letters?: boolean
  words?: boolean
  onHint: (val: string) => void
}

export const ClueLevels = ({
  count,
  letters,
  words,
  onHint = (val: string) => {},
}: Props) => {
  const classes = classnames('p-3 flex mx-0.5 rounded dark:text-white')
  const getHighlight = (val?: boolean) => {
    const on = {
      'bg-green-500 text-white border-green-500': val || false,
    }
    return on
  }

  const classesCounts = classnames(classes, getHighlight(count))
  const classesLetters = classnames(classes, getHighlight(letters))
  const classesWords = classnames(classes, getHighlight(words))

  const onClick = (value: string) => () => {
    onHint(value)
  }

  return (
    <div className="flex flex-row justify-center">
      <div className={classes}>Analysis Level</div>
      <button className={classesCounts} onClick={onClick('count')}>
        I
      </button>
      <button className={classesLetters} onClick={onClick('letters')}>
        II
      </button>
      <button className={classesWords} onClick={onClick('words')}>
        III
      </button>
    </div>
  )
}
