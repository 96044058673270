import { LetterGuess, LetterStatus } from '../../lib/words'
import { CurrentRow } from './CurrentRow'

type Props = {
  moveToNext: boolean
  guesses: string[]
  currentGuessState: LetterGuess
  onCellClick: (index: number, value?: string, status?: LetterStatus) => void
}

export const Grid = ({ moveToNext, currentGuessState, onCellClick }: Props) => {
  return (
    <div className="pb-6">
      <CurrentRow
        moveToNext={moveToNext}
        onCellClick={onCellClick}
        currentGuessState={currentGuessState}
      />
    </div>
  )
}
