import { GAME_TITLE } from '../constants/strings'
import { LetterGuess, LetterProps } from './words'

export const getResultsString = (
  gameState: LetterGuess,
  sep = '\t',
  includeLink = true
) => {
  return `Wordle (w/${GAME_TITLE} analysis)\n\n${generateEmojiGrid(
    gameState,
    sep
  )}${includeLink ? `\n\nSee how you did today: https://lexible.net` : ''}`
}

export const shareStatus = (gameState: LetterGuess) => {
  const shareStat = getResultsString(gameState)
  navigator.clipboard.writeText(shareStat)
}

export const getAnalysisString = (
  letterGuess: LetterGuess,
  sep: string = '\t'
) => {
  const isWin = letterGuess.letters.reduce<boolean>(
    (ret: boolean, l: LetterProps) => {
      return ret && l.status === 'correct'
    },
    true
  )
  if (isWin) {
    return `${sep}GOT IT!`
  }
  if (!!letterGuess.analysis) {
    const kills = letterGuess.alphabetStatus.filter(
      (st) => st.status === 'killed'
    )
    if (letterGuess.analysis.possibleCount > 100) {
      return `${sep}word poss. 100+${sep} • ${sep}letters elim. ${`${kills.length}`.padEnd(
        2
      )}`
    }
    return `${sep}word poss. ${`${letterGuess.analysis.possibleCount}`.padEnd(
      3,
      ' '
    )} ${sep} • ${sep}letters elim. ${`${kills.length}`.padEnd(2)}`
  }
  return ''
}

export const generateEmojiGrid = (
  gameState: LetterGuess,
  sep: string = '\t'
) => {
  return gameState.history
    .map((letterGuess) => {
      const statusString = letterGuess.letters
        .map((letter: LetterProps, idx: number) => {
          switch (letter.status) {
            case 'correct':
              return '🟩'
            case 'present':
              return '🟨'
            default:
              return '⬜'
          }
        })
        .join('')
      const analyisString = getAnalysisString(letterGuess, sep)
      return `${statusString}${sep}${analyisString}`
    })
    .join('\n')
}
