import classnames from 'classnames'

type Props = {
  count?: number
  visible?: boolean
}

export const Possibilities = ({ count, visible }: Props) => {
  const getCountString = (ct: number) => {
    if (ct <= 0) {
      return ''
    } else if (ct > 100) {
      return '100+'
    } else {
      return ct
    }
  }
  const classes = classnames(
    'mx-3 p-3 m-5 w-60 h-18 border-solid border-slate-400 dark:border-slate-700 border-2 flex  mx-0.5 text-lg font-bold rounded dark:text-white'
  )

  return visible ? (
    <div className={classes}>Possibilities: {getCountString(count || 0)}</div>
  ) : null
}
