import classnames from 'classnames'
import { useState } from 'react'
import { LetterAnalysis } from '../../lib/words'
import { ClueLevels } from './ClueLevels'
import { Possibilities } from './Possibilities'
import { TopLetters } from './TopLetters'
import { TopWords } from './TopWords'

type Props = {
  currentAnalysis: LetterAnalysis
}

export const ClueGrid = ({ currentAnalysis }: Props) => {
  const [showHintOne, setShowHintOne] = useState(true)
  const [showHintTwo, setShowHintTwo] = useState(false)
  const [showHintThree, setShowHintThree] = useState(false)
  const classes = classnames(
    'm-3 flex flex-row border-solid align-items-center justify-center text-lg font-bold rounded dark:text-white',
    {
      'bg-white dark:bg-slate-900 border-slate-200 dark:border-slate-600': true,
    }
  )
  const onHintClick = (val: string) => {
    if (val === 'count') {
      setShowHintOne(!showHintOne)
    } else if (val === 'letters') {
      setShowHintTwo(!showHintTwo)
    } else if (val === 'words') {
      setShowHintThree(!showHintThree)
    }
  }

  return (
    <div className={classes}>
      <div className="justify-center">
        <ClueLevels
          count={showHintOne}
          letters={showHintTwo}
          words={showHintThree}
          onHint={onHintClick}
        />
        <Possibilities
          count={currentAnalysis.possibleCount}
          visible={showHintOne}
        />
        <TopLetters
          letterCounts={currentAnalysis.letterCounts}
          visible={showHintTwo}
        />
        <TopWords ranks={currentAnalysis.wordRanks} visible={showHintThree} />
      </div>
    </div>
  )
}
