import { WORDS } from '../constants/wordlist'
import { VALIDGUESSES } from '../constants/validGuesses'
export const wordLen = 5

export type LetterStatus = 'unknown' | 'killed' | 'present' | 'correct'

export type LetterProps = {
  status?: LetterStatus
  letter?: string
  index: number
  alert?: boolean
}

export class WordRank {
  word: string
  score: number

  constructor(wd: string, cnt: number) {
    this.word = wd
    this.score = cnt
  }
}

export class LetterCount {
  letter: string
  count: number
  constructor(lttr: string, cnt: number) {
    this.letter = lttr
    this.count = cnt
  }
}

export class LetterAnalysis {
  possibleCount: number
  letterCounts: Array<LetterCount>
  wordRanks: Array<WordRank>

  constructor(
    possibles: number,
    ltrCnts: Array<LetterCount>,
    wordRnks: Array<WordRank>
  ) {
    this.possibleCount = possibles
    this.letterCounts = [...ltrCnts]
    this.wordRanks = [...wordRnks]
  }
}

function getMatchRegex(letterStates: Array<LetterProps>) {
  let letterRegex = []
  for (let i = 0; i < wordLen; i++) {
    const matchAtLocation = letterStates.find(
      (ls) => ls.status === 'correct' && i === ls.index
    )
    if (matchAtLocation) {
      letterRegex.push(matchAtLocation.letter)
    } else {
      letterRegex.push('.')
    }
  }
  return letterRegex.join('')
}

function getLettersByStatus(letterStates: Array<LetterProps>, status: string) {
  const mustHaves = letterStates.filter((ls) => ls.status === status)
  return mustHaves.map((ls) => ls.letter?.toUpperCase())
}

function getMustHaves(letterStates: Array<LetterProps>) {
  const presents = getLettersByStatus(letterStates, 'present')
  const corrects = getLettersByStatus(letterStates, 'correct')
  const newArr: Array<string | undefined> = []
  for (const p of presents) {
    if (!newArr.includes(p)) {
      newArr.push(p)
    }
  }
  for (const c of corrects) {
    if (!newArr.includes(c)) {
      newArr.push(c)
    }
  }
  return newArr
}

function getKills(letterStates: Array<LetterProps>) {
  return getLettersByStatus(letterStates, 'killed')
}

function getCantBes(letterStates: Array<LetterProps>) {
  const mustHaves = letterStates.filter((ls) => ls.status === 'present')
  return mustHaves.map((ls) => {
    const letterRegex = []
    for (let i = 0; i < wordLen; i++) {
      if (ls.index === i) {
        letterRegex.push(ls.letter?.toUpperCase())
      } else {
        letterRegex.push('.')
      }
    }
    return letterRegex.join('')
  })
}

function getMechanics(letterStates: Array<LetterProps>) {
  const matchRegex = getMatchRegex(letterStates)
  const mustHaves = getMustHaves(letterStates)
  const cantBe = getCantBes(letterStates)
  const kills = getKills(letterStates)

  return { matchRegex, mustHaves, cantBe, kills }
}

function hasYellows(cantBe: Array<string>, word: string) {
  let hasYellow = false
  for (let i = 0; i < cantBe.length; i++) {
    const yell = cantBe[i]
    const yellowMatch = word.match(yell)
    hasYellow = hasYellow || !!(yellowMatch && yellowMatch.length > 0)
  }
  return hasYellow
}

function analyzeLetters(letterStates: Array<LetterProps>) {
  const { matchRegex, mustHaves, cantBe, kills } = getMechanics(letterStates)

  const possibles = [...WORDS.map((w) => w.toUpperCase())] //[ ... new Set([... words, ... options]) ]

  let possibleCount = 0
  let filteredPossibles: Array<string> = []
  let letterCount: any = {}
  possibles.forEach((word: string) => {
    const matches = word.match(matchRegex)
    const killIt = kills.reduce((kill, letter: any) => {
      return kill || word.indexOf(letter) >= 0
    }, false)
    const hasIt = mustHaves.reduce((mustHave, letter: any) => {
      return mustHave && word.indexOf(letter) >= 0
    }, true)
    const yellows = hasYellows(cantBe, word)

    if (hasIt && !yellows && !killIt && matches && matches.length > 0) {
      possibleCount = possibleCount + 1
      filteredPossibles.push(word)
      for (const letterIndex in word.split('')) {
        const letter: string = word[letterIndex]
        if (!mustHaves.includes(letter)) {
          if (letterCount[letter]) {
            letterCount[letter] = letterCount[letter] + 1
          } else {
            letterCount[letter] = 1
          }
        }
      }
    }
  })

  // sort values
  var sortable = []
  for (const letter of Object.keys(letterCount)) {
    sortable.push([letter, letterCount[letter]])
  }

  sortable.sort(function (a, b) {
    return b[1] - a[1]
  })

  // score the remaining options and suggest top 5
  var possibilitiesScore = []
  for (
    let possibleWordIdx = 0;
    possibleWordIdx < filteredPossibles.length;
    possibleWordIdx++
  ) {
    const possibleWord = filteredPossibles[possibleWordIdx]
    let score = 0
    const lettersSeen: any[] = []
    for (
      let letterIndex = 0;
      letterIndex < possibleWord.length;
      letterIndex++
    ) {
      const letter: any = possibleWord.toUpperCase().charAt(letterIndex)
      const scalar = lettersSeen.includes(letter) ? 0.5 : 1
      score = score + (letterCount[letter] || 0) * scalar
      lettersSeen.push(letter)
    }
    possibilitiesScore.push({
      possibleWord,
      score,
    })
  }

  // sort values
  var rank = []
  for (const word of possibilitiesScore) {
    rank.push([word.possibleWord, word.score])
  }

  rank.sort(function (a: any, b: any) {
    return b[1] - a[1]
  })

  const letterRanks = []
  for (let r = 0; r < sortable.length; r++) {
    letterRanks.push(new LetterCount(sortable[r][0], sortable[r][1]))
  }

  const wordRanks = []
  for (let r = 0; r < rank.length; r++) {
    wordRanks.push(new WordRank(`${rank[r][0]}`, parseInt(`${rank[r][1]}`)))
  }
  return new LetterAnalysis(possibleCount, letterRanks, wordRanks)
}

export class LetterGuess {
  moveToNext: boolean
  letters: Array<LetterProps>
  alphabetStatus: Array<LetterProps>
  history: Array<LetterGuess>
  analysis?: LetterAnalysis

  constructor(ltrs: Array<LetterProps>, kls: Array<LetterProps>) {
    this.letters = [...ltrs]
    this.alphabetStatus = [...kls]
    this.moveToNext = false
    this.history = []
  }

  addToHistory(lg: LetterGuess) {
    this.history.push(lg)
  }

  setHistory(lg: Array<LetterGuess>) {
    this.history = [...lg]
  }

  getUsedLetters(): Array<string> {
    return this.letters.map((l) => l.letter || '')
  }

  getUsedLetterProps(): Array<LetterProps> {
    return [...this.letters]
  }

  hasValueAt(index: number) {
    return this.letters.length > index
  }

  showPossiblesAt(index: number) {
    const gr = this.getPossiblesGrid()
    let remainingPossibles = []
    for (let j = 0; j < wordLen; j += 1) {
      if (j < this.letters.length) {
        remainingPossibles.push(this.letters[j].letter)
      } else if (j < wordLen) {
        const indexPoss = this.getPossiblesForIndex(j, gr[j])
        remainingPossibles.push(...indexPoss.map((g) => g.letter))
      }
    }
    const uniques: Array<string | undefined> = []
    for (let o = 0; o < remainingPossibles.length; o++) {
      if (!uniques.includes(remainingPossibles[o])) {
        uniques.push(remainingPossibles[o])
      }
    }

    const allpossibles = this.getPossibles()
    console.log('show possibles at: ', index, allpossibles, gr[index], uniques)
    const hasMissingValues = allpossibles.reduce((val, poss) => {
      return val && uniques.includes(poss.letter)
    }, true)
    return !this.hasValueAt(index) || !hasMissingValues
  }

  setMoveToNextLetter(b: boolean) {
    this.moveToNext = b
    const lg = new LetterGuess([...this.letters], [...this.alphabetStatus])
    lg.setHistory(this.history)
    lg.moveToNext = this.moveToNext
    return lg
  }

  outputAnalysis(analysis: LetterAnalysis): void {
    const { possibleCount, letterCounts, wordRanks } = analysis
    console.log('WordPossibles:', possibleCount)
    console.log('WordSortable:', letterCounts)
    console.log('WordRank:', wordRanks)
  }

  getLastLetter(): string | undefined {
    return this.getLastLetterStatus()?.letter
  }

  letterCheck(): Array<LetterProps> {
    const letterList = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('')
    const newAlphabetStatus: Array<LetterProps> = []
    for (const letter of letterList) {
      const letterCurrents = this.letters.filter((l) => l.letter === letter)
      const previousLetters = this.alphabetStatus.filter(
        (l) => l.letter === letter
      )
      if (letterCurrents) {
        newAlphabetStatus.push(...letterCurrents)
      }
      newAlphabetStatus.push(...previousLetters)
    }
    const uniques: Array<LetterProps> = []
    for (const letter of newAlphabetStatus) {
      const dupes = newAlphabetStatus.filter(
        (p) => letter.letter === p.letter && letter.index !== p.index
      )
      const prev = uniques.find(
        (l) =>
          l.index === letter.index &&
          l.letter === letter.letter &&
          l.status === letter.status
      )
      if (
        !(
          prev ||
          (letter.status === 'killed' &&
            dupes.find((d) => d.status === 'correct' || d.status === 'present'))
        )
      ) {
        uniques.push(letter)
      }
    }

    return uniques
  }

  getCorrects(): Array<LetterProps> {
    return this.getByStatus('correct', this.alphabetStatus)
  }

  getPossiblePlaces(
    letter: LetterProps,
    possibles: Array<LetterProps>
  ): Array<number> {
    const possibleSpots = []
    if (letter.status === 'correct') {
      possibleSpots.push(letter.index)
    } else {
      for (let i = 0; i < wordLen; i++) {
        const preventer = possibles.find(
          (l) =>
            l.letter === letter.letter &&
            l.index === i &&
            l.status === 'present'
        )
        if (!preventer) {
          possibleSpots.push(i)
        }
      }
    }
    return possibleSpots
  }

  getPossiblesForIndex(index: number, possibles: Array<LetterProps>) {
    return possibles.filter((p) => {
      const possibleSpots = this.getPossiblePlaces(p, possibles)
      return possibleSpots.includes(index)
    })
  }

  onlyOnePossibleValue(
    index: number,
    perfects: Array<LetterProps>,
    possibles: Array<LetterProps>
  ): LetterProps | undefined {
    const perfect = perfects.find((l) => l.index === index)
    if (perfect) {
      return perfect
    }

    const onlys = possibles.filter((lett) => {
      const possiblePlaces = this.getPossiblePlaces(lett, possibles)
      const minusPerfects = possiblePlaces.filter((idx) => {
        return perfects.length === 0 || !perfects.find((l) => l.index === idx)
      })
      return minusPerfects.length === 1 && minusPerfects[0] === index
    })
    return onlys && onlys.length === 1 ? onlys[0] : undefined
  }

  getPossibles(): Array<LetterProps> {
    return this.getByStatus('present', this.alphabetStatus)
  }

  getPossiblesGrid(): Array<Array<LetterProps>> {
    const perfects = this.getCorrects()
    const possibles = this.getPossibles().filter((pos) => {
      const perfectLetters = perfects.map((l) => l.letter || '')
      return !perfectLetters.includes(pos.letter || '')
    })
    // const missingPossibles = this.getMissingPossibles()
    // console.log('MISSING POSSIBLES', missingPossibles)

    const grid = []
    for (let i = 0; i < wordLen; i++) {
      const onlyOne = this.onlyOnePossibleValue(i, perfects, possibles)
      if (onlyOne) {
        // only one possible, must be green
        grid.push([onlyOne])
      } else if (possibles.length >= 1) {
        // if we have letter possibilities to show
        const poss = this.getPossiblesForIndex(i, possibles)
        grid.push([...poss])
      } else {
        // just blank?
        grid.push([])
      }
    }
    return grid
  }

  getByStatus(status: LetterStatus, list: Array<LetterProps>) {
    return list.filter((l) => l.status === status)
  }

  update(): LetterGuess {
    const newAlphabetStatus = this.letterCheck()
    const analysis = analyzeLetters(newAlphabetStatus)
    const nextLG = new LetterGuess([], newAlphabetStatus)
    console.log('CURRENTLY SETTING', analysis)
    this.analysis = analysis
    this.alphabetStatus = [...newAlphabetStatus]
    nextLG.setHistory(this.history)
    nextLG.addToHistory(this)
    return nextLG
  }

  killRemaining() {
    for (let i = 0; i < this.letters.length; i++) {
      if (this.letters[i].status === 'unknown') {
        this.letters[i].status = 'killed'
      }
    }
    const nextState = new LetterGuess(
      [...this.letters],
      [...this.alphabetStatus]
    )
    nextState.setHistory(this.history)
    return nextState
  }

  analyze(): LetterAnalysis {
    const newAlphabetStatus = this.letterCheck()
    //console.log('current Letters', this.letters)
    //console.log('Analysis New Alpha->', newAlphabetStatus)
    const analysis = analyzeLetters(newAlphabetStatus)
    this.outputAnalysis(analysis)
    return analysis
  }

  lettersFilled(): boolean {
    return this.letters.length >= 5
  }

  hasHadSomeAction(): boolean {
    return this.letters.length > 0 || this.alphabetStatus.length > 0
  }

  readyToSuggest(): boolean {
    const allValid = this.letters.reduce((retVal, letterStatus): boolean => {
      return retVal && letterStatus.status !== 'unknown'
    }, true)
    return this.letters.length === 5 && allValid
  }

  getLastLetterStatus(): LetterProps | undefined {
    for (let i = this.letters.length - 1; i >= 0; i = i - 1) {
      if (!!this.letters[i]) {
        return this.letters[i]
      }
    }
    return undefined
  }

  getLetterStatus(letter: string): LetterStatus | undefined {
    // first look through letters
    const letterStatus = this.letters.find((st) => st.letter === letter)
    if (letterStatus) {
      return letterStatus.status
    }

    const historyStatus = this.alphabetStatus.find((st) => st.letter === letter)
    if (historyStatus) {
      return historyStatus.status || 'unknown'
    }

    // then look through alphabetStatus
    return 'unknown'
  }

  nextStatus(status?: LetterStatus): LetterStatus {
    if (status === 'unknown') {
      return 'killed'
    } else if (status === 'killed') {
      return 'present'
    } else if (status === 'present') {
      return 'correct'
    }
    return 'killed'
  }

  delete(): LetterGuess {
    this.letters = [...this.letters.slice(0, -1)]
    const nextState = new LetterGuess(
      [...this.letters],
      [...this.alphabetStatus]
    )
    nextState.setHistory(this.history)
    return nextState
  }

  updateStatus(index: number): LetterGuess {
    if (index > -1 && index < this.letters.length) {
      this.letters[index].status = this.nextStatus(this.letters[index].status)
      const nextState = new LetterGuess(
        [...this.letters],
        [...this.alphabetStatus]
      )
      nextState.setHistory(this.history)
      return nextState
    } else {
      return this
    }
  }

  isAProblemLetter(letter: string, index: number): boolean {
    const isKilled = !!this.alphabetStatus.find(
      (a) => a.status === 'killed' && a.letter === letter
    )
    const isYellowHere = !!this.alphabetStatus.find(
      (a) => a.status === 'present' && a.letter === letter && a.index === index
    )
    const isGreenHere = !!this.alphabetStatus.find(
      (a) => a.status === 'correct' && a.index === index && a.letter !== letter
    )
    return isKilled || isYellowHere || isGreenHere
  }

  getExistingStatus(letter: string, index: number): LetterStatus | undefined {
    const isGreenHere = this.alphabetStatus.find(
      (a) => a.status === 'correct' && a.letter === letter && a.index === index
    )
    if (isGreenHere) {
      return isGreenHere.status
    }
    const isYellowHere = this.alphabetStatus.find(
      (a) => a.status === 'present' && a.letter === letter && a.index === index
    )
    if (isYellowHere) {
      return isYellowHere.status
    }
    return undefined
  }

  rotate(index: number): LetterGuess {
    if (index >= 0 && index < this.letters.length) {
      return this.updateStatus(index)
    } else {
      return this
    }
  }

  change(newLetter: string): LetterGuess {
    console.log('Changing letter', newLetter)
    if (
      (newLetter !== this.getLastLetter() || this.moveToNext) &&
      !this.lettersFilled()
    ) {
      this.moveToNext = false

      const isAlertLetter = this.isAProblemLetter(
        newLetter,
        this.letters.length
      )

      const existingStatus = this.getExistingStatus(
        newLetter,
        this.letters.length
      )

      const nextLetter: LetterProps = {
        status: existingStatus || 'unknown',
        letter: newLetter,
        index: this.letters.length,
        alert: isAlertLetter,
      }
      const newArr = [...this.letters, nextLetter]
      const nextGuess = new LetterGuess(newArr, this.alphabetStatus)
      nextGuess.setHistory(this.history)
      return nextGuess
    }
    if (newLetter === this.getLastLetter() && !this.moveToNext) {
      this.moveToNext = false
      return this.updateStatus(this.letters.length - 1)
    }
    this.moveToNext = false
    return this
  }

  getWord(): string {
    return this.letters.map((l) => l.letter || '').join('')
  }
}

export const getWord = (letters: LetterGuess) => {
  return letters.getWord()
}

export const isWordInWordList = (word: string) => {
  return (
    WORDS.includes(word.toLowerCase()) ||
    VALIDGUESSES.includes(word.toLowerCase())
  )
}

export const isWinningWord = (word: string) => {
  return solution === word
}

export const getWordOfDay = () => {
  // January 1, 2022 Game Epoch
  const epochMs = new Date('January 1, 2022 00:00:00').valueOf()
  const now = Date.now()
  const msInDay = 86400000
  const index = Math.floor((now - epochMs) / msInDay)
  const nextday = (index + 1) * msInDay + epochMs

  return {
    solution: WORDS[index % WORDS.length].toUpperCase(),
    solutionIndex: index,
    tomorrow: nextday,
  }
}

export const { solution, solutionIndex, tomorrow } = getWordOfDay()
