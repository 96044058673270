import classnames from 'classnames'
import { LetterCount } from '../../lib/words'

type Props = {
  letterCounts: Array<LetterCount>
  visible?: boolean
}

export const TopLetters = ({ letterCounts, visible }: Props) => {
  const classes = classnames(
    'mx-3 p-3 m-3 w-60 h-18 border-solid border-2 border-slate-400 dark:border-slate-700 flex mx-0.5 text-lg font-bold rounded dark:text-white'
  )

  const showers = letterCounts.slice(0, 3)
  return visible ? (
    <div className={classes}>
      Top Letters:
      <br />
      {showers.map((l) => `${l.letter}`).join(', ')}
    </div>
  ) : null
}
