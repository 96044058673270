import { LetterGuess, LetterStatus } from '../../lib/words'
import { Cell } from './Cell'

type Props = {
  currentGuessState: LetterGuess
  moveToNext: boolean
  onCellClick: (index: number, value?: string, status?: LetterStatus) => void
}

export const CurrentRow = ({
  moveToNext,
  currentGuessState,
  onCellClick,
}: Props) => {
  const splitGuess = currentGuessState.letters
  const highlightNext = moveToNext
    ? [
        <Cell
          highlight={true}
          onClick={onCellClick}
          key={splitGuess.length}
          location={splitGuess.length + 1}
        />,
      ]
    : []
  const emptyCells =
    5 - (splitGuess.length + highlightNext.length) >= 0
      ? Array.from(Array(5 - (splitGuess.length + highlightNext.length)))
      : []
  return (
    <div className="flex justify-center mb-1">
      {splitGuess.map((letter) => (
        <Cell
          onClick={onCellClick}
          key={letter.index}
          highlight={false}
          location={letter.index}
          value={letter.letter}
          status={letter.status}
          warn={letter.alert || false}
        />
      ))}
      {highlightNext.map((cell) => cell)}
      {emptyCells.map((_, i) => (
        <Cell
          onClick={onCellClick}
          highlight={false}
          key={i}
          location={splitGuess.length + highlightNext.length + i}
        />
      ))}
    </div>
  )
}
