import { ReactNode } from 'react'
import classnames from 'classnames'
import { LetterStatus, LetterProps } from '../../lib/words'

type Props = {
  location: number
  highlight?: boolean
  children?: ReactNode
  values?: Array<LetterProps>
  usedLetters: Array<string>
  showValues: boolean
  warn?: boolean
  onClick: (index: number, value?: string, status?: LetterStatus) => void
}

export const PossiblesCell = ({
  values,
  showValues,
  usedLetters,
  onClick,
}: Props) => {
  const handleClick = (
    clickIndex: number,
    clickValue?: string,
    clickStatus?: LetterStatus
  ) => {
    console.log('PossCellClick', clickValue, clickIndex, clickStatus)
    if (onClick) {
      onClick(clickIndex, clickValue, clickStatus)
    }
  }

  const localClick = (index: number) => {
    if (valueAt(index) !== ' ') {
      handleClick(index, valueAt(index), undefined)
    }
  }

  const valueAt = (index: number) => {
    if (values) {
      const letters: Array<string> = []
      for (const l of values) {
        if (l && l.letter && !letters.includes(l.letter)) {
          letters.push(l.letter)
        }
      }
      if (
        letters.length > index &&
        showValues &&
        !usedLetters.includes(letters[index])
      ) {
        return letters[index]
      }
    } else {
      return ' '
    }
  }

  const classes = classnames(
    'w-14 h-14 flex items-center justify-center mx-0.5 text-lg font-bold rounded dark:text-white'
    // 'w-14 h-14 border-solid border-2 flex items-center justify-center mx-0.5 text-lg font-bold rounded dark:text-white',
    // {
    //   'border-white-200': highlight,
    //   'cell-animation': !!values,
    //   'border-solid border-4 border-red-200 dark:border-red-400': !!warn,
    // }
  )
  const classesBox = classnames(
    'border-2 rounded',
    {
      'border-solid border-slate-500': showValues,
      'border-transparent': !showValues,
    }
    // 'w-14 h-14 border-solid border-2 flex items-center justify-center mx-0.5 text-lg font-bold rounded dark:text-white',
    // {
    //   'border-white-200': highlight,
    //   'cell-animation': !!values,
    //   'border-solid border-4 border-red-200 dark:border-red-400': !!warn,
    // }
  )

  return (
    <div className={classesBox}>
      <div className="col-6 flex flex-row">
        <div className="col-6">
          <button
            style={{ width: `25px`, height: '25px' }}
            className={classes}
            onClick={() => {
              localClick(0)
            }}
          >
            {valueAt(0)}
          </button>
        </div>
        <div className="col-6">
          <button
            style={{ width: `25px`, height: '25px' }}
            className={classes}
            onClick={() => {
              localClick(1)
            }}
          >
            {valueAt(1)}
          </button>
        </div>
      </div>
      <div className="col-6 flex flex-row">
        <div className="col-6">
          <button
            style={{ width: `25px`, height: '25px' }}
            className={classes}
            onClick={() => {
              localClick(2)
            }}
          >
            {valueAt(2)}
          </button>
        </div>
        <div className="col-6">
          <button
            style={{ width: `25px`, height: '25px' }}
            className={classes}
            onClick={() => {
              localClick(3)
            }}
          >
            {valueAt(3)}
          </button>
        </div>
      </div>
    </div>
  )
}
