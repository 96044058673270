import { ReactNode } from 'react'
import classnames from 'classnames'
import { LetterStatus } from '../../lib/words'

type Props = {
  location: number
  highlight?: boolean
  children?: ReactNode
  value?: string
  status?: LetterStatus
  warn?: boolean
  onClick: (index: number, value?: string, status?: LetterStatus) => void
}

export const Cell = ({
  highlight = false,
  value,
  status,
  location,
  children,
  warn,
  onClick,
}: Props) => {
  const handleClick = (
    clickIndex: number,
    clickValue?: string,
    clickStatus?: LetterStatus
  ) => {
    console.log('clickcell', clickValue, clickIndex, clickStatus)
    if (onClick) {
      onClick(clickIndex, clickValue, clickStatus)
    }
    //event.currentTarget.blur()
  }

  const classes = classnames(
    'w-14 h-14 border-solid border-2 flex items-center justify-center mx-0.5 text-lg font-bold rounded dark:text-white',
    {
      'border-white-200': highlight,
      'bg-white dark:bg-slate-900 border-slate-200 dark:border-slate-600':
        !status && !highlight,
      'border-black dark:border-slate-100': value && !status && !highlight,
      'bg-slate-400 dark:bg-slate-700 text-white border-slate-400 dark:border-slate-700':
        status === 'killed',
      'bg-green-500 text-white border-green-500': status === 'correct',
      'bg-yellow-500 dark:bg-yellow-600 text-white border-yellow-500 dark:border-yellow-600':
        status === 'present',
      'cell-animation': !!value,
      'border-solid border-4 border-red-200 dark:border-red-400': !!warn,
    }
  )

  return (
    <button
      style={{ width: `58px`, height: '58px' }}
      className={classes}
      onClick={() => {
        handleClick(location, value, status)
      }}
    >
      {children || value}
    </button>
  )
}
