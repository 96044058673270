import { LetterGuess, LetterStatus } from '../../lib/words'
import { ScratchRow } from '../grid/ScratchRow'

type Props = {
  currentGuessState: LetterGuess
  onScratchPadClick: (
    index: number,
    value?: string,
    status?: LetterStatus
  ) => void
}

export const ScratchPad = ({ currentGuessState, onScratchPadClick }: Props) => {
  return (
    <div className="w-80 mx-auto align-self-center align-content-center align-items-center justify-content-center mb-8">
      <h1 className="ml-2 m-3 text-sm growfont-bold dark:text-white">KNOWNS</h1>
      <ScratchRow
        onCellClick={onScratchPadClick}
        currentGuessState={currentGuessState}
      />
    </div>
  )
}
