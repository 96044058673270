import { LetterGuess } from '../../lib/words'
import { shareStatus, getResultsString } from '../../lib/share'
import { BaseModal } from './BaseModal'
import { ANALYSIS_TITLE, SHARE_TEXT } from '../../constants/strings'

type Props = {
  isOpen: boolean
  handleClose: () => void
  gameStatus: LetterGuess
  handleShare: () => void
}

export const StatsModal = ({
  isOpen,
  handleClose,
  gameStatus,
  handleShare,
}: Props) => {
  if (!gameStatus.history || gameStatus.history.length < 1) {
    return (
      <BaseModal
        title={ANALYSIS_TITLE}
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <div>
          Enter your Wordle results with statuses and get your analysis here
        </div>
      </BaseModal>
    )
  }
  return (
    <BaseModal title={ANALYSIS_TITLE} isOpen={isOpen} handleClose={handleClose}>
      <div className="m-5 w-full text-start">
        {getResultsString(gameStatus, `\u00A0\u0009`, false)
          .split('\n')
          .map((l) => (
            <div
              key={l}
              className="flex justify-left text-start w-full font-monospace text-sm dark:text-white"
            >
              {l}
            </div>
          ))}
      </div>
      <button
        type="button"
        className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
        onClick={() => {
          shareStatus(gameStatus)
          handleShare()
        }}
      >
        {SHARE_TEXT}
      </button>
    </BaseModal>
  )
}
