import classnames from 'classnames'
import { WordRank } from '../../lib/words'

type Props = {
  ranks: Array<WordRank>
  visible?: boolean
}

export const TopWords = ({ ranks, visible }: Props) => {
  const capitalizeFirstLetter = (word: string) => {
    return (
      word.toLowerCase().charAt(0).toUpperCase() + word.toLowerCase().slice(1)
    )
  }

  const classes = classnames(
    'mx-3 p-3 m-5 w-60 h-18 border-solid border-2 border-slate-400 dark:border-slate-700 flex mx-0.5 text-lg font-bold rounded dark:text-white'
  )
  const topWords = ranks.length > 12 ? ranks.slice(0, 10) : ranks
  return visible ? (
    <div className={classes}>
      Top Words:
      <br />
      <div className="p-3 m-7 d-flex flex-column">
        {topWords.map((rank) => {
          return <div key={rank.word}>{capitalizeFirstLetter(rank.word)}</div>
        })}
      </div>
    </div>
  ) : null
}
