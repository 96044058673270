import {
  QuestionMarkCircleIcon,
  ChartBarIcon,
  LightBulbIcon,
} from '@heroicons/react/outline'
import { useState, useEffect } from 'react'
import { Alert } from './components/alerts/Alert'
import { Grid } from './components/grid/Grid'
import { ScratchPad } from './components/clues/ScratchPad'
import { ClueGrid } from './components/clues/ClueGrid'
import { Keyboard } from './components/keyboard/Keyboard'
import { AboutModal } from './components/modals/AboutModal'
import { InfoModal } from './components/modals/InfoModal'
import { StatsModal } from './components/modals/StatsModal'
import {
  GAME_TITLE,
  ABOUT_GAME_MESSAGE,
  RESET_GAME_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  CORRECT_WORD_MESSAGE,
} from './constants/strings'
import {
  solution,
  LetterGuess,
  LetterStatus,
  LetterAnalysis,
  wordLen,
} from './lib/words'

import './App.css'

const ALERT_TIME_MS = 2000

function App() {
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches
  const [currentGuess, setCurrentGuess] = useState('')
  const [moveToNextLetter, setMoveToNextLetter] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [isNotEnoughLetters, setIsNotEnoughLetters] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
      ? true
      : false
  )
  const [currentGuessState, setCurrentGuessState] = useState<LetterGuess>(
    () => {
      return new LetterGuess([], [])
    }
  )
  const [currentAnalysis, setCurrentAnalysis] = useState<LetterAnalysis>(() => {
    return new LetterAnalysis(-1, [], [])
  })

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [isDarkMode])

  const resetGame = () => {
    const nextState = new LetterGuess([], [])
    setCurrentAnalysis(new LetterAnalysis(-1, [], []))
    setCurrentGuessState(nextState)
    setMoveToNextLetter(false)
    setCurrentGuess(nextState.getWord())
  }

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  const onCellClick = (
    index: number,
    value?: string,
    status?: LetterStatus
  ) => {
    // if we click on the next letter you are saying that the previous
    // letter is done (allows double letters)
    if (currentGuess.length === index) {
      setMoveToNextLetter(true)
      setCurrentGuessState(currentGuessState.setMoveToNextLetter(true))
    } else {
      setMoveToNextLetter(false)
      setCurrentGuessState(currentGuessState.setMoveToNextLetter(false))
    }
    if (currentGuess.length <= 5) {
      const nextLetterState = currentGuessState.updateStatus(index)
      setCurrentGuessState(nextLetterState)
      setCurrentGuess(nextLetterState.getWord())
    }
  }

  const onScratch = (index: number, value?: string, status?: LetterStatus) => {
    if (value) {
      if (currentGuess.length <= 5) {
        const nextLetterState = currentGuessState.change(value)
        setCurrentGuessState(nextLetterState)
        setCurrentGuess(nextLetterState.getWord())
      } else {
        setCurrentGuessState(currentGuessState.setMoveToNextLetter(false))
      }
      setMoveToNextLetter(false)
    }
  }

  const onChar = (value: string) => {
    if (value >= '1' && value <= `${wordLen}`) {
      const nextLetterState = currentGuessState.rotate(parseInt(value) - 1)
      setCurrentGuessState(nextLetterState)
      setCurrentGuess(nextLetterState.getWord())
    } else {
      if (currentGuess.length <= 5) {
        const nextLetterState = currentGuessState.change(value)
        setCurrentGuessState(nextLetterState)
        setCurrentGuess(nextLetterState.getWord())
      } else {
        setCurrentGuessState(currentGuessState.setMoveToNextLetter(false))
      }
      setMoveToNextLetter(false)
    }
  }

  const onDelete = () => {
    setCurrentGuessState(currentGuessState.delete())
    setCurrentGuess(currentGuessState.getWord())
  }

  const onEnter = (special: boolean): void => {
    if (special) {
      const nextState = currentGuessState.killRemaining()
      setCurrentGuessState(nextState)
      setCurrentGuess(nextState.getWord())
    } else {
      if (
        !currentGuessState.readyToSuggest() &&
        currentGuessState.hasHadSomeAction()
      ) {
        setIsNotEnoughLetters(true)
        setTimeout(() => {
          setIsNotEnoughLetters(false)
        }, ALERT_TIME_MS)
      } else if (currentGuessState.hasHadSomeAction()) {
        const analysis: LetterAnalysis = currentGuessState.analyze()
        const nextState = currentGuessState.update()
        setCurrentGuessState(nextState)
        setCurrentGuess(nextState.getWord())
        setCurrentAnalysis(analysis)
        console.log('CURRENTLY AT', nextState)
      }
    }
  }

  return (
    <div className="py-8 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="d-flex flex-column mx-auto">
        <div className="flex w-80 mx-auto align-self-center align-content-center align-items-center justify-content-center mb-8 mt-12">
          <h1 className="ml-2 text-xl grow font-bold dark:text-white">
            {GAME_TITLE}
          </h1>
          <ChartBarIcon
            className="h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsStatsModalOpen(true)}
          />
          <LightBulbIcon
            className="h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => handleDarkMode(!isDarkMode)}
          />
          <QuestionMarkCircleIcon
            className="h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsInfoModalOpen(true)}
          />
        </div>
        <Grid
          moveToNext={moveToNextLetter}
          onCellClick={onCellClick}
          guesses={[]}
          currentGuessState={currentGuessState}
        />
        <ScratchPad
          onScratchPadClick={onScratch}
          currentGuessState={currentGuessState}
        />
        <Keyboard
          currentGuessState={currentGuessState}
          onChar={onChar}
          onDelete={onDelete}
          onEnter={onEnter}
        />
        <ClueGrid currentAnalysis={currentAnalysis} />
        <button
          type="button"
          className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-yellow-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
          onClick={() => resetGame()}
        >
          {RESET_GAME_MESSAGE}
        </button>
        <AboutModal
          isOpen={isAboutModalOpen}
          handleClose={() => setIsAboutModalOpen(false)}
        />
        <StatsModal
          isOpen={isStatsModalOpen}
          handleClose={() => setIsStatsModalOpen(false)}
          gameStatus={currentGuessState}
          handleShare={() => {
            console.log('SHARED')
          }}
        />
        <button
          type="button"
          className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
          onClick={() => setIsAboutModalOpen(true)}
        >
          {ABOUT_GAME_MESSAGE}
        </button>
      </div>
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <AboutModal
        isOpen={isAboutModalOpen}
        handleClose={() => setIsAboutModalOpen(false)}
      />
      <Alert message={NOT_ENOUGH_LETTERS_MESSAGE} isOpen={isNotEnoughLetters} />
      <Alert message={CORRECT_WORD_MESSAGE(solution)} isOpen={false} />
    </div>
  )
}

export default App
